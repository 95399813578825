<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters>
        <v-col cols="12" md="10">
          <div class="mt-2 txt_search">
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Search for people"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData(tab)"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-end">
          <div class="mt-2 d-flex align-center">
            <h4 class="mr-2">Show</h4>
            <v-menu
              v-model="menuLimit"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="60px"
                  max-width="60px"
                  outlined
                  depressed
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p class="ma-0 default_txt">{{ limit }}</p>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="changeLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4" align="center">
        <v-col cols="12" md="4" class="py-2">
          <bullet :title="'List Tes Psikologi'" />
        </v-col>
      </v-row>
    </div>
    <div class="mt-3 px-1">
      <v-tabs
        left
        v-model="tab"
        style="border-radius: 8px 8px 0px 0px; overflow: hidden"
      >
        <v-tab
          :class="tab == 0 ? 'active' : 'unactive'"
          style="border-radius: 8px 0px 0px 0px"
          @click="fetchData(0)"
          >Event Schedule
        </v-tab>
        <v-tab
          :class="tab == 1 ? 'active' : 'unactive'"
          style="border-radius: 0px 8px 0px 0px"
          @click="fetchData(1)"
          >Done
        </v-tab>
      </v-tabs>
      <v-skeleton-loader
        class="pa-2 elevation-0"
        type="table-tbody"
        v-if="loading"
      ></v-skeleton-loader>
      <v-data-table
        v-if="!loading && dataSet"
        :headers="headers"
        :items="dataSet"
        hide-default-footer
        :page.sync="page"
        :items-per-page="limit"
        class="elevation-0"
        :single-select="false"
      >
        <template v-slot:[`item.absensi.tgl_absen`]="{ item }">
          <div v-if="item.absensi" class="d-flex">
            {{ $date(new Date(item.absensi.tgl_absen)).format("MMM DD, YYYY") }}
          </div>
        </template>
        <template v-slot:[`item.absensi.wkt_absen`]="{ item }">
          <div v-if="item.absensi" class="d-flex">
            {{ item.absensi.wkt_absen }}
          </div>
        </template>
        <template v-slot:[`item.start_time`]="{ item }">
          <div v-if="item.start_time" class="d-flex">
            {{
              $date(item.start_time).format("DD MMM YYYY") +
              " s/d " +
              $date(item.end_time).format("DD MMM YYYY")
            }}
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div>
            <v-card
              flat
              class="py-1 px-2 rounded"
              :color="($date(item.event_date_start + item.event_time_start).format('DD MMM YYYY HH:MM:SS') <= $date().format('DD MMM YYYY HH:MM:SS') && $date(item.event_date_end + item.event_time_end).format('DD MMM YYYY HH:MM:SS') >= $date().format('DD MMM YYYY HH:MM:SS'))
               ? '#26AF65' 
               : '#052633'"
            >
              <p
                class="medium_txt pa-0 ma-0 text-capitalize"
                style="color: white; text-align: center"
              >
                {{ ($date(item.event_date_start + item.event_time_start).format("DD MMM YYYY HH:MM:SS") <= $date().format("DD MMM YYYY HH:MM:SS") && $date(item.event_date_end + item.event_time_end).format("DD MMM YYYY HH:MM:SS") >= $date().format("DD MMM YYYY HH:MM:SS"))
                    ?'Open'
                    :'Close' 
                }}
              </p>
            </v-card>
          </div>
        </template>
      </v-data-table>
      <div class="text-center pa-3">
        <v-pagination
          color="#052633"
          v-model="page"
          @input="fetchData"
          :length="last_page"
        ></v-pagination>
      </div>
    </div>
    <div class="pa-2">
      <dialog-small
        :d_small="d_small"
        @refetch="fetchData(tab)"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, NavbarProfile },
  name: "listTest",
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      menuLimit: false,
      tab: 0,
      find: "",
      loading: true,
      page: 1,
      limit: 10,
      last_page: null,
      total_page: null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Tes Psikologi",
          disabled: true,
          href: "#",
        },
      ],
      headers: [
        { text: "ID", value: "id" },
        {
          text: "Kode Event",
          value: "code_test",
          width: "20%",
          sortable: false,
        },
        { text: "Lokasi", value: "location", sortable: false },
        {
          text: "Peserta",
          value: "total",
        },
        {
          text: "Schedule",
          value: "start_time",
          sortable: false,
        },
        { text: "Status", value: "status", sortable: false },
      ],
      dataSet: null,
      options : {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      },
    };
  },
  mounted() {
    this.fetchData(this.tab);
  },
  methods: {
    // get data tes psikologi dari dashboard hara anargya
    fetchData(tab) {
      this.loading = true;
      let tempPath = `sysadmin/tes-psikologi/list?page[size]=${this.limit}&page[number]=${this.page}`;

      if (tab == 1) {
        console.log("done");
        tempPath = `sysadmin/tes-psikologi/list/done?page[size]=${this.limit}&page[number]=${this.page}`;
      }
      let data = {
        path: tempPath,
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          console.log(data);
          this.dataSet = data.data.data;
          this.last_page = data.data.last_page;
          this.total_page = data.data.total;
          this.loading = false;
          this.$store.dispatch("notify", {
            msg: data.message,
            type: "success",
          });
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },

    // delete data tes psikologi dari dashboard hara anargya
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/admin/invoice/delete`,
        deleteType: "Tes Psikologi",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Tes Psikologi";
      this.d_small = true;
      this.line = true;
    },

    // ubah limit data yg tampil per page
    changeLimit(val) {
      this.limit = val;
      this.fetchData(this.tab);
    },
  },
};
</script>

<style scoped>
.unactive {
  background: #cdd0d1;
  color: #9ba0a3 !important;
  text-transform: capitalize;
}
.active {
  border: 1px solid #cdd0d1;
  color: black !important;
  box-sizing: border-box;
  text-transform: capitalize;
}
</style>
